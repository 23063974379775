import Plot from 'react-plotly.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, CardContent, Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Flex from '../_global/Flex';
import Legend from './Legend';
import { upperCaseFirst, surgicalMethodAlias } from '../helpers.js';
import { colorMap } from '../theme';

const titleMap = {
    procedure_time_avg: 'Average Procedure Time (mins)',
    los_avg: 'Average Length of Stay (days)',
    readmissions_percentage: 'Percentage of Readmission (%)',
    non_home_discharge_percentage: 'Percentage of Non-Home Discharge (%)',
};

const cardTitleMap = {
    procedure_time_avg: 'Average Procedure Time',
    los_avg: 'Average Length of Stay',
    readmissions_percentage: 'Percentage of Readmission',
    non_home_discharge_percentage: 'Percentage of Non-Home Discharge',
};

const outcomeNicenameMap = {
    procedure_time_avg: 'Procedure Time Avg',
    los_avg: 'LOS Avg',
    readmissions_percentage: 'Readmission',
    non_home_discharge_percentage: 'Non-Home Discharge',
};

const outcomeKeyNameMap = {
    procedure_time_avg: 'procedure_time_mins_avg',
    los_avg: 'los_days_avg',
    non_home_discharge_percentage: 'non_home_discharge_percentage',
    readmissions_percentage: 'readmission_percentage'
};

export default function({ 
    loading, 
    appliedTargetSelection: ats, 
    data, 
    volumes
}) {
    const [chartData, setChartData] = useState(null);
    const [loadingLocal, setLoadingLocal] = useState(false);

    useEffect(() => {if (loading) setLoadingLocal(true)}, [loading]);
    useEffect(() => {
        if (!data) return;
        const { outcome, surgical_method } = ats;
        let surgicalMethods = [];
        if (surgical_method === 'all') surgicalMethods = ['robotic', 'laparoscopic', 'open', 'other'];
        else surgicalMethods.push(surgical_method);
        const res = data.reduce((acc, { start_date: datetime, site_name, surgeon_name, site_uuid, surgeon_uuid, is_population, data }) => {
            surgicalMethods.forEach(sm => {
                const lineIdentifier = is_population ? `${upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'}_na_${sm}` : `${site_name || surgeon_name || ats?.target_name}_${site_uuid || surgeon_uuid}_${sm}`;
                if (!acc[lineIdentifier]) acc[lineIdentifier] = [];
                const outcome_value = ['readmissions_percentage', 'non_home_discharge_percentage'].includes(outcome) ? data[sm][outcomeKeyNameMap[outcome]] * 100 : data[sm][outcomeKeyNameMap[outcome]];
                acc[lineIdentifier].push({
                    date: datetime.split('T')[0],
                    outcome_value
                });
            });
            return acc;
        }, {});
        const lines = Object.entries(res).map(([identifier, coordinates]) => {
            const [name, uuid, surgical_method] = identifier.split('_');
            return {
                type: 'scatter', 
                mode: 'lines',
                x: coordinates.map(({ date }) => date),
                y: coordinates.map(({ outcome_value }) => outcome_value),
                marker: { 
                    color: ats?.target_uuid === uuid ? colorMap.individual[surgical_method] : colorMap.population[surgical_method]
                },
                text: coordinates.map(({ date, outcome_value }) => 
                    `${name}<br>${surgicalMethodAlias(ats?.service_line, surgical_method)}<br>Date:${date}<br>${outcomeNicenameMap[outcome]}: ${outcome_value.toFixed(1)}`),
            }
        });
        setChartData(lines);
        setLoadingLocal(false);
    }, [data, ats]);

    function areDatesLessThan3MonthsApart(date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);
        const monthDiff = Math.abs(
            (date2.getFullYear() - date1.getFullYear()) * 12 +
            (date2.getMonth() - date1.getMonth())
        );
        return monthDiff < 3;
    }

    if (areDatesLessThan3MonthsApart(ats.start_date, ats.end_date)) return (
        <Card style={styles.card}>
            <CardContent style={{ flexDirection: 'column', margin: '.5vw', overflow: 'hidden' }}>
                <Flex jcsb style={{ flexWrap: 'wrap' }}>
                    <Flex>
                        <div style={{ height: '20px', fontWeight: 'bold' }}>{cardTitleMap[ats?.outcome]}</div>
                        {ats?.outcome === 'procedure_time_avg' && <Box sx={{ zIndex: 100, display: { xs: 'none', md: 'flex', xl: 'flex' }}}><Tooltip title="Procedure time is calculated from first incision to last close." placement="right" style={{ transform: 'translate(10px, -2px)', color: '#666666', fontSize: '18px'}}><InfoIcon /></Tooltip></Box>}
                    </Flex>
                </Flex>
                <Flex f={1} alss column aic style={{ 
                        // width: false ? 400 : 500, 
                        height: false ? 300 : 300,
                        paddingTop: 20
                    }}>
                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{`Timeline view unavailable`}</div>
                    <div style={{ fontSize: '16px', marginTop: 100 }}>Timeline view is only available for timeframe's 3 months or higher</div>
                </Flex>
                <Legend loading={loading} volumes={volumes} appliedTargetSelection={ats}></Legend>
            </CardContent>
        </Card>
    );

    return (
        <Card style={styles.card}>
            <CardContent style={{ flexDirection: 'column', margin: '.5vw', overflow: 'hidden' }}>
                <Flex jcsb style={{ flexWrap: 'wrap' }}>
                    <Flex>
                        <div style={{ height: '20px', fontWeight: 'bold' }}>{cardTitleMap[ats?.outcome]}</div>
                        {ats?.outcome === 'procedure_time_avg' && <Box sx={{ zIndex: 100, display: { xs: 'none', md: 'flex', xl: 'flex' }}}><Tooltip title="Procedure time is calculated from first incision to last close." placement="right" style={{ transform: 'translate(10px, -2px)', color: '#666666', fontSize: '18px'}}><InfoIcon /></Tooltip></Box>}
                    </Flex>
                </Flex>
                {/* WEB LAYOUT */}
                <Box sx={{ display: { xs: 'none', md: 'flex' }, marginTop: '-70px', marginRight: '-100px', justifyContent: 'center' }}>
                    {loading || loadingLocal ? <LoadingChart /> : <Chart data={chartData} config={{displayModeBar: false}} yTitle={titleMap[ats.outcome]}/>}
                </Box>
                {/* MOBILE LAYOUT */}
                <Box sx={{display: { xs: 'flex', md: 'none' }, marginTop: '-70px', marginRight: '-100px', justifyContent: 'center' }}>
                    {loading || loadingLocal ? <LoadingChart /> : <Chart isMobile data={chartData} config={{displayModeBar: false}} yTitle={titleMap[ats.outcome]}/>}
                </Box>
                <Legend loading={loading} volumes={volumes} appliedTargetSelection={ats}></Legend>
            </CardContent>
        </Card>
    );
};

function Chart({ data, isMobile, yTitle }) {
    return <Plot 
        data={data} 
        layout={{   
            font: {
                size: isMobile ? 9 : 10
            },
            width: 700,
            height: 400,
            tickfont: {
                family: 'Roboto',
                size: isMobile ? 8 : 10,
            },
            xaxis: {
                title: 'Date',
                dtick: 'M1',  
                tickformat: '%b %Y'
            },
            yaxis: {
                title: yTitle,
                // range: [0, parseFloat(maxRangeY) + parseFloat(maxRangeY) * .2],
            },
            showlegend: false,
            plot_bgcolor: 'rgba(0,0,0,0)',
            paper_bgcolor: 'rgba(0,0,0,0)',
        }} 
        config={{ displayModeBar: false }} 
        style={{ transform: 'translate(-35px, 0px)' }}
    />
}

function LoadingChart() {
    return (
        <Flex f={1} alss aic style={{ width: 700, height: 320, marginTop: 80 }}>
            <Flex className={'load-placeholder'} style={{ width: 20, height: 180, marginRight: 10, marginBottom: 20 }} />
            <Flex column aic style={{ width: 600, height: 250 }}>
                <Flex className={'load-placeholder'} f={1} alss/>
                <Flex className={'load-placeholder'} style={{ width: 200, height: 20, marginTop: 10 }} />
            </Flex>
        </Flex>
    );
}

const styles = {
    card: { 
        flex: 1,
        margin: '.5vw', 
        fontSize: '12px', 
        flexWrap: 'wrap', 
        overflowWrap: 'normal',
        minWidth: 650,
        maxWidth: 750    
    },
    circle: {
        width: '11px',
        height: '11px',
        borderRadius: '50%'
    },
    legendText: {
        marginLeft: 3
    }
}